import {
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	Link,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'd3';
import { apiClient, endpoints, onlyDateFormatter } from '../../../../utils';
import { ExpandLess, ExpandMore, Notifications, NotificationsOff, TrendingDown, TrendingUp } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { strings } from '../../../../utils/strings';
import { useAuthContext } from '../../../../context';
import { DetailsHeader } from './style';
import React, { useState } from 'react';
import { usePrivateAppContext } from '../../../../context/PrivateAppContext';
import Collapse from '@mui/material/Collapse';
import { defaultCalculatorData } from '../../../../interfaces/IInquiryCalculator';
import mixpanel from 'mixpanel-browser';

const TargetPriceCard = ({ inquiry }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { setTargetPrices, targetPrices } = useAuthContext();
	const [isAccordionCollapsed, setAccordionCollapsed] = useState(false);
	const { setQuickInquiryCalculator } = usePrivateAppContext();

	const onMonitoringStop = async () => {
		mixpanel.track('🧮🟥 Stop Target Price Monitoring', {
			CompanyId: inquiry?.fields['Company'][0],
			InquiryId: inquiry?.id,
			Referrer: window.location.pathname,
		});

		const payload = {
			inquiryId: inquiry?.id,
		};

		const res = await apiClient.post(endpoints.disableTarget, payload);

		if (res.status === 200) {
			toast.success(
				strings.successMessages.targetPrice.stopMonitorCompany.replace('{company}', inquiry.fields['Company Name'][0])
			);
			setTargetPrices(targetPrices.filter((targetPrice) => targetPrice.id !== inquiry?.id));
		}
	};

	const renderFinancialInfoItem = (report) => {
		const { label, primary, secondary, trend } = report;

		return (
			<Stack
				direction={isMobile ? 'row' : 'column'}
				spacing={isMobile ? 1 : '4px'}
				width={isMobile ? undefined : 210}
				alignItems={isMobile ? 'flex-start' : undefined}
			>
				<Typography variant={'body2'} color={'text.secondary'} flex={isMobile ? 1 : undefined} flexShrink={0}>
					{label}
				</Typography>
				<Stack
					direction={'row'}
					alignItems={trend ? 'center' : 'baseline'}
					flexWrap={'wrap'}
					useFlexGap={true}
					flex={isMobile ? 1 : undefined}
				>
					<Stack spacing={1} direction={'row'} alignItems={'center'} mr={1}>
						{trend === 'up' && <TrendingUp sx={{ color: 'success.main', fontSize: 20 }} />}
						{trend === 'down' && <TrendingDown sx={{ color: 'error.main', fontSize: 20 }} />}
						<Typography
							variant={'body2'}
							flexShrink={0}
							color={trend === 'up' ? 'success.main' : trend === 'down' ? 'error.main' : undefined}
						>
							{primary}
						</Typography>
					</Stack>
					{Boolean(secondary) && (
						<Typography variant={'caption'} color={'text.secondary'} fontStyle={'italic'} flexShrink={0}>
							{secondary}
						</Typography>
					)}
				</Stack>
			</Stack>
		);
	};

	const handleCreateInquiry = async () => {
		setQuickInquiryCalculator({
			companyId: inquiry.fields.Company[0],
			calculator: {
				...defaultCalculatorData,
				blockSize: inquiry.fields['Block Size'],
				sharePrice: inquiry.fields.PPS,
				numberOfShares: inquiry.fields.Shares,
			},
		});
	};

	return (
		<Card key={inquiry.id} sx={{ borderLeft: `2px solid ${theme.palette.primary.main}` }}>
			<CardContent sx={{ paddingBottom: `${isAccordionCollapsed ? 0 : 16}px !important` }}>
				<Stack spacing={'20px'} direction={'column'}>
					<Stack spacing={isMobile ? '18px' : '20px'} direction={'column'}>
						<Stack direction={'row'} alignItems={'center'}>
							<Stack direction={'column'} spacing={1} flex={1} alignItems={'flex-start'}>
								<Stack direction={'row'} spacing={1} alignItems={'center'}>
									<Notifications color="primary" fontSize="small" />
									<Typography variant={'overline'} color={'primary.main'} lineHeight={'normal'}>
										Target price
									</Typography>
								</Stack>
								<Stack spacing={2} alignItems={'center'} direction={'row'}>
									<Link
										component={RouterLink}
										color={'text.primary'}
										underline={'hover'}
										variant="h5"
										to={`/company/${inquiry.fields.Company[0]}`}
									>
										{inquiry.fields['Company Name'][0]}
									</Link>
									{!isMobile && <Chip label="Monitoring" variant="outlined" color={'secondary'} size={'small'} />}
								</Stack>
							</Stack>
							{!isMobile && (
								<Button
									size={'small'}
									startIcon={<NotificationsOff />}
									variant={'outlined'}
									color={'error'}
									onClick={onMonitoringStop}
								>
									stop Monitoring
								</Button>
							)}
						</Stack>
						<Stack spacing={isMobile ? '6px' : '40px'} direction={!isMobile ? 'row' : 'column'}>
							<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
								<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
									Started monitoring at
								</Typography>
								<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
									{onlyDateFormatter(new Date(inquiry.fields['Created At']))}
								</Typography>
							</Stack>

							{Boolean(inquiry.fields.PPS) && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Target price
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format('$,.2f')(inquiry.fields.PPS)}
									</Typography>
									{inquiry.fields['Went Live'] && (
										<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
											{inquiry.fields.Price ? format('$,.2f')(inquiry.fields.Price) : 'N/A'}
										</Typography>
									)}
								</Stack>
							)}

							{Boolean(inquiry.fields.Shares) && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Number of shares
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format(',')(inquiry.fields.Shares)}
									</Typography>
									{inquiry.fields['Went Live'] && (
										<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
											{inquiry.fields.QTY ? format(',')(inquiry.fields.QTY) : 'N/A'}
										</Typography>
									)}
								</Stack>
							)}

							{inquiry.fields['Block Size'] && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Block size
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format('$,.2f')(inquiry.fields['Block Size'])}
									</Typography>
								</Stack>
							)}
						</Stack>
						<Stack alignItems={'flex-start'}>
							<Paper elevation={5} sx={{ boxShadow: 'none', pt: 1, pb: 1, pl: '10px', pr: '10px' }}>
								<Typography variant={'body2'} color={'text.secondary'}>
									To proceed with a full inquiry and onboarding,{' '}
									<Link
										component={RouterLink}
										to={`#`}
										onClick={handleCreateInquiry}
										underline={'hover'}
										// dataCy={'calculator-inquiry'}
									>
										click here
									</Link>
									.
								</Typography>
							</Paper>
						</Stack>
						{isMobile && (
							<Button
								size={'large'}
								startIcon={<NotificationsOff />}
								variant={'outlined'}
								color={'error'}
								onClick={onMonitoringStop}
							>
								stop Monitoring
							</Button>
						)}
					</Stack>
					<Divider variant={'fullWidth'} />
					<Stack direction={'column'} spacing={'20px'}>
						<DetailsHeader onClick={() => setAccordionCollapsed((prevState) => !prevState)}>
							<Typography variant={'body1'}>Market Overview</Typography>
							{isAccordionCollapsed ? (
								<ExpandMore sx={{ color: theme.palette.grey['500'] }} />
							) : (
								<ExpandLess sx={{ color: theme.palette.grey['500'] }} />
							)}
						</DetailsHeader>
						<Collapse in={!isAccordionCollapsed}>
							<Paper elevation={4} sx={{ boxShadow: 'none', borderRadius: '6px', p: 2 }}>
								<Stack
									direction={isMobile ? 'column' : 'row'}
									rowGap={isMobile ? '10px' : 3}
									columnGap={1}
									useFlexGap={true}
									flexWrap={isMobile ? undefined : 'wrap'}
								>
									{inquiry.fields.companyReport?.fields?.map((report, i) => (
										<React.Fragment key={i}>{renderFinancialInfoItem(report)}</React.Fragment>
									))}
								</Stack>
							</Paper>
						</Collapse>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default TargetPriceCard;
