import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { divide, multiply } from '../../math.utils';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CustomToggleButton from '../CustomToggleButton';
import { INQUIRY_ACTION } from '../../interfaces/IInquiry';

interface InquiryCalculatorProps {
	title: string;

	action: INQUIRY_ACTION;
	setAction: (action: INQUIRY_ACTION) => void;

	blockSize: number;
	setBlockSize: (blockSize: number) => void;

	sharePrice: number;
	setSharePrice: (sharePrice: number) => void;

	shareNumber: number;
	setShareNumber: (shareNumber: number) => void;

	submitButton?: React.ReactNode;

	sharesIssued: number;
	elevation?: number;
}

const InquiryCalculator: React.FC<InquiryCalculatorProps> = (props) => {
	const { title, submitButton, sharesIssued } = props;
	const { action, setAction } = props;
	const { blockSize, setBlockSize } = props;
	const { sharePrice, setSharePrice } = props;
	const { shareNumber, setShareNumber } = props;
	const { elevation } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [valuation, setValuation] = useState(sharesIssued ? sharePrice * sharesIssued : undefined);

	const toggleBuySell = () => {
		setAction(action === INQUIRY_ACTION.SELL ? INQUIRY_ACTION.BUY : INQUIRY_ACTION.SELL);
	};

	const sharePriceChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setSharePrice(value);
		setBlockSize(sourceInfo.source === 'event' ? multiply(value, shareNumber) : blockSize);

		if (sourceInfo.source === 'event' && isFinite(sharesIssued)) {
			setValuation(multiply(sharesIssued, value));
		}
	};

	const valuationChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setValuation(value);

		if (sourceInfo.source === 'event') {
			setSharePrice(divide(sharesIssued, value));
		}
	};

	const numberSharesChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setShareNumber(value);
		setBlockSize(sourceInfo.source === 'event' ? multiply(value, sharePrice) : blockSize);
	};

	const blockSizeChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value || sourceInfo.source !== 'event') {
			return;
		}

		setBlockSize(value);
		setShareNumber(sourceInfo.source === 'event' ? divide(value, sharePrice) : shareNumber);
	};

	return (
		<Card elevation={elevation}>
			{title && <CardHeader title={title} />}
			<CardContent sx={{ paddingTop: isMobile ? 0 : undefined }}>
				<Stack spacing={3} direction={'column'}>
					{/*<Paper elevation={14} sx={{ boxShadow: 'none' }}>
						<Box paddingX={'14px'} paddingY={'10px'} borderRadius={4}>
							<Typography variant={'body2'} color={'text.primary'}>
								To access all bids and comprehensive market pricing you must start by logging a basic inquiry on our
								platform to create your trading dashboard.
							</Typography>
						</Box>
					</Paper>*/}
					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							I'm interested in
						</Typography>
						<Box width={'100%'}>
							<CustomToggleButton
								leftLabel="Selling"
								rightLabel="Buying"
								callback={toggleBuySell}
								position={action === INQUIRY_ACTION.SELL ? 'left' : 'right'}
							/>
						</Box>
					</Stack>

					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							Price per share
						</Typography>
						<NumericFormat
							value={sharePrice}
							onValueChange={sharePriceChanged}
							thousandSeparator={true}
							fixedDecimalScale={true}
							decimalScale={2}
							prefix="$"
							inputMode="numeric"
							customInput={TextField}
							size={isMobile ? 'medium' : 'small'}
							data-cy={'calculator-pps'}
						/>
					</Stack>
					{action === INQUIRY_ACTION.BUY && (
						<Stack spacing={'6px'} direction={'column'}>
							<Typography variant={'body1'} color={'text.secondary'}>
								Valuation
							</Typography>
							<NumericFormat
								value={valuation}
								onValueChange={valuationChanged}
								thousandSeparator={true}
								decimalScale={2}
								prefix="$"
								inputMode="numeric"
								customInput={TextField}
								size={isMobile ? 'medium' : 'small'}
							/>
						</Stack>
					)}
					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							Shares
						</Typography>
						<NumericFormat
							value={shareNumber}
							onValueChange={numberSharesChanged}
							thousandSeparator={true}
							inputMode="numeric"
							customInput={TextField}
							size={isMobile ? 'medium' : 'small'}
							data-cy={'calculator-shares'}
						/>
					</Stack>
					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							Block size
						</Typography>
						<NumericFormat
							value={blockSize}
							onValueChange={blockSizeChanged}
							thousandSeparator={true}
							prefix="$"
							decimalScale={2}
							inputMode="numeric"
							customInput={TextField}
							size={isMobile ? 'medium' : 'small'}
						/>
					</Stack>
				</Stack>
			</CardContent>
			{submitButton && <CardActions sx={{ justifyContent: 'center' }}>{submitButton}</CardActions>}
		</Card>
	);
};

export default InquiryCalculator;
