import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { airtableDateParse, apiClient, endpoints } from '../utils';
import { defaultCalculatorData, IInquiryCalculator } from '../interfaces/IInquiryCalculator';

interface PriceData {
	date: Date;
	bid: number | null;
	ask: number | null;
	par: number | null;
	market: number | null;
	institutional: number | null;
	retail: number | null;
	high: number | null;
	low: number | null;
	trading: number | null;
	tradingActivitySell: number | null;
	sharesIssued: number | null;
	postMoneyValuation: number | null;
}

interface CompanyData {
	id?: string;
	name?: string;
	location?: string;
	sectors?: string[];
	website?: string;
	rofr?: string;
	asignee?: string;
	activity?: string;
	institutionalCoeff?: number;
	institutional?: number;
	retailCoeff?: number;
	sharesIssued?: number;
	postMoneyValuation?: number;
	par?: number;
	similar?: string[];
	tradingActivitySell?: number;
	latestPriceDate?: Date;
	formattedFields?: any;
}

interface FundingData {
	date: Date;
	security: string;
	multiple: number;
	postMoneyValuation: number;
	sharesAuthorized: number;
	priceShare: number;
	notes: string;
}

interface CompanyContextType {
	data: {
		priceData?: PriceData[];
		overviewData?: CompanyData;
		fundingData?: FundingData[];
		investors?: any[];
	};
	calculatorData: IInquiryCalculator;
	loading: boolean;
}

const defaultValue: CompanyContextType = {
	data: {},
	loading: true,
	calculatorData: defaultCalculatorData,
};

// Create context
const CompanyContext = createContext<CompanyContextType>(defaultValue);

// Provider component
export const CompanyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { companyId } = useParams();

	const [data, setData] = useState(defaultValue.data);
	const [calculatorData, setCalculatorData] = useState(defaultValue.calculatorData);
	const [loading, setLoading] = useState(defaultValue.loading);

	useEffect(() => {
		if (companyId) {
			apiClient
				.get(endpoints.companyDetails.replace('{companyId}', companyId))
				.then((res) => {
					const data = res.data;
					const priceData: PriceData[] = [];

					const sortedRaw = data.overview.priceHistory
						.filter((item: any) => item.fields.Date)
						.sort(
							(a: any, b: any) =>
								airtableDateParse(a.fields.Date).getTime() - airtableDateParse(b.fields.Date).getTime()
						);

					sortedRaw.forEach((item: any) => {
						const current: Partial<PriceData> = {
							par: null,
							bid: null,
							ask: null,
							market: null,
							institutional: null,
							retail: null,
							high: null,
							low: null,
							trading: null,
						};

						if (+item.fields.Bid) {
							current.bid = +item.fields.Bid;
							current.institutional = current.bid * +item.fields.Institutional;
						}
						if (+item.fields.Ask) {
							current.ask = +item.fields.Ask;
							current.retail = current.ask * +item.fields.Retail;
						}
						if (+item.fields.Par) current.par = +item.fields.Par;
						if (+item.fields.Bid || +item.fields.Ask) current.market = (current.bid + current.ask) / 2;

						priceData.push({
							date: airtableDateParse(item.fields.Date),
							bid: current.bid,
							ask: current.ask,
							par: current.par,
							market: current.market,
							institutional: current.institutional,
							retail: current.retail,
							high: +item.fields['High'],
							low: +item.fields['Low'],
							trading: +item.fields['Trading'],
							tradingActivitySell: +item.fields['TradingActivitySell'],
							sharesIssued: +item.fields['Shares Issued Calc'],
							postMoneyValuation: +item.fields['Post Money Valuation'],
						});
					});

					const lph = priceData.length === 0 ? null : priceData[priceData.length - 1];

					const companyData: CompanyData = {};
					const companyRaw = data.overview;
					if (companyRaw) {
						companyData.id = companyRaw.id;
						companyData.name = companyRaw.fields['Legal Name'];
						companyData.location = companyRaw.fields['Location'];
						companyData.sectors = companyRaw.fields['Sectors'];
						companyData.website = companyRaw.fields['Website'];
						companyData.rofr = companyRaw.fields['ROFR'];
						companyData.asignee = companyRaw.fields['Asignee'];
						companyData.activity = companyRaw.fields['Activity'];

						companyData.institutionalCoeff = lph?.institutional;
						companyData.institutional = lph?.institutional;
						companyData.formattedFields = companyRaw.fields['formattedFields'];
						if (!companyData.institutionalCoeff) companyData.institutionalCoeff = 0.8;

						companyData.retailCoeff = lph?.retail;
						if (!companyData.retailCoeff) companyData.retailCoeff = 1.05;

						companyData.sharesIssued = lph?.sharesIssued;
						companyData.postMoneyValuation = lph?.postMoneyValuation;
						companyData.par = lph?.par;
						companyData.similar = companyRaw.fields['Similar'];
						companyData.tradingActivitySell = lph?.tradingActivitySell;
						companyData.latestPriceDate = lph?.date;
					}

					const fundingData: FundingData[] = data?.funding.map((item: any) => ({
						date: airtableDateParse(item.fundingDate),
						security: item.security,
						postMoneyValuation: +item.postMoneyValuation,
						sharesAuthorized: +item.sharesAuthorized,
						priceShare: +item.priceShare,
						notes: item.notes,
					}));

					setData({
						priceData,
						overviewData: companyData,
						fundingData: fundingData.filter((d) => d.date).sort((a, b) => b.date.getTime() - a.date.getTime()),
						investors: data?.investors ?? [],
					});

					if (priceData.length > 0) {
						const tradingAt = lph?.trading;
						const shareNumber = Math.floor(defaultCalculatorData.blockSize / tradingAt);
						const blockSize = shareNumber * tradingAt;
						const sharesIssued = lph?.sharesIssued ?? 0;

						setCalculatorData((prevState) => ({
							...prevState,
							sharePrice: tradingAt,
							shareNumber: shareNumber,
							blockSize: blockSize,
							sharesIssued: sharesIssued,
						}));
					}
				})
				.catch((e) => console.error('useData error: ', e))
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	}, [companyId]);

	return <CompanyContext.Provider value={{ data, loading, calculatorData }}>{children}</CompanyContext.Provider>;
};

export const useCompanyContext = () => {
	return useContext(CompanyContext);
};
