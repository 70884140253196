import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { apiClient, endpoints } from '../utils';
import { useLoaderContext } from './LoaderContext';
import { useAuthContext } from './AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IInquiryCalculator } from '../interfaces/IInquiryCalculator';

interface PrivateAppContextProps {
	current: any;
	setCurrent: (company: any) => void;
	companies: Array<any>;
	setCompanies: (companies: Array<any>) => void;
	quickInquiryCalculator: {
		companyId: any;
		calculator: IInquiryCalculator;
	} | null;
	setQuickInquiryCalculator: (quickInquiryCalculator: IInquiryCalculator | null) => void;
}

const PrivateAppContext = createContext<PrivateAppContextProps>({
	current: null,
	setCurrent: () => {},
	companies: [],
	setCompanies: () => {},
	quickInquiryCalculator: null,
	setQuickInquiryCalculator: () => {},
});

export const PrivateAppProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [current, setCurrent] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [quickInquiryCalculator, setQuickInquiryCalculator] = useState(null);

	const { closeLoader } = useLoaderContext();
	const { authenticated } = useAuthContext();

	useEffect(() => {
		if (authenticated) {
			const referer = searchParams.get('referer');

			if (referer) {
				navigate(referer);
			}

			loadCompanies()
				.then((res) => {
					setCompanies(res);
				})
				.finally(() => {
					closeLoader();
				});
		}
	}, [authenticated]);

	const loadCompanies = async (): Promise<Array<any>> => {
		try {
			const res = await apiClient.get(endpoints.getAllCompanies);
			return res.data ?? [];
		} catch (err) {
			return [];
		}
	};

	return (
		<PrivateAppContext.Provider
			value={{
				current,
				setCurrent,
				companies,
				setCompanies,
				quickInquiryCalculator,
				setQuickInquiryCalculator,
			}}
		>
			{children}
		</PrivateAppContext.Provider>
	);
};

export const usePrivateAppContext = (): PrivateAppContextProps => useContext(PrivateAppContext);
