import { Dialog, IconButton, useMediaQuery, useTheme } from '@mui/material';
import InquiryCalculator from '../InquiryCalculator';
import React, { useEffect, useState } from 'react';
import { useAuthContext, usePrivateAppContext } from '../../context';
import { defaultCalculatorData, IInquiryCalculator } from '../../interfaces/IInquiryCalculator';
import { ArrowForward, Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiClient, constants, endpoints, strings } from '../../utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const QuickInquiryDialog = () => {
	const navigate = useNavigate();
	const { quickInquiryCalculator, setQuickInquiryCalculator } = usePrivateAppContext();
	const { inquiries, setInquiries, user } = useAuthContext();
	const [calculatorState, setCalculatorState] = useState<IInquiryCalculator>(defaultCalculatorData);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [isButtonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		if (quickInquiryCalculator) {
			setCalculatorState(quickInquiryCalculator.calculator);
			mixpanel.track('📋🟢 Open quick inquiry dialog', {
				CompanyId: quickInquiryCalculator.companyId,
				Referrer: window.location.pathname,
			});
		} else {
			setCalculatorState(defaultCalculatorData);
		}
	}, [quickInquiryCalculator]);

	const onSubmit = async () => {
		setButtonLoading(true);
		mixpanel.track('📋🚀 Submit quick inquiry', {
			CompanyId: quickInquiryCalculator.companyId,
			Referrer: window.location.pathname,
		});

		const payload = {
			action: calculatorState.action,
			blockSize: Math.round(calculatorState.blockSize * 1e2) / 1e2,
			companyId: quickInquiryCalculator.companyId,
			sharePrice: Math.round(calculatorState.sharePrice * 1e2) / 1e2,
			numberOfShares: calculatorState.shareNumber,
			sharesIssued: calculatorState.sharesIssued,

			referrer: window.location.origin,
			// message: `to _${calculatorState.action}_ ${format(',.0f')(calculatorState.shareNumber)} shares of \`${
			// 	companyData.companyName
			// }\`, block size is \`$${format(',.0f')(calculatorState.blockSize)}\` at share price of \`$${format(',.2f')(
			// 	calculatorState.sharePrice
			// )}\``,
			shadow: !!user.shadow,
		};

		try {
			const res = await apiClient.post(endpoints.logInterest, payload);

			if (res.status === 200) {
				toast.success(strings.successMessages.inquiry.created);
				setInquiries([res.data, ...inquiries]);

				const jointOnboarding = res.data.activityLogs?.find(
					(activityLog) => activityLog.fields.Type === constants.ACTIVITY_LOG.TYPE.JointOnboarding
				);

				setQuickInquiryCalculator(null);
				if (jointOnboarding) {
					navigate('/home/onboarding/' + res.data.id + '/' + jointOnboarding.id);
				} else {
					navigate('/');
				}
			}
		} catch (err) {
			toast.error(strings.errorMessages.inquiry.create);
		} finally {
			setButtonLoading(false);
		}
	};

	const onClose = () => {
		mixpanel.track('📋🔴 Close quick inquiry dialog', {
			CompanyId: quickInquiryCalculator.companyId,
			Referrer: window.location.pathname,
		});
		setQuickInquiryCalculator(null);
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth={isMobile ? undefined : 'xs'}
			onClose={onClose}
			open={Boolean(quickInquiryCalculator)}
		>
			<IconButton size={'large'} sx={{ position: 'absolute', right: 16, top: 16 }} onClick={onClose}>
				<Close />
			</IconButton>
			<InquiryCalculator
				elevation={10}
				title={'Quick start'}
				action={calculatorState.action}
				setAction={(value) => setCalculatorState((prevState) => ({ ...prevState, action: value }))}
				blockSize={calculatorState.blockSize}
				setBlockSize={(value) => setCalculatorState((prevState) => ({ ...prevState, blockSize: value }))}
				sharePrice={calculatorState.sharePrice}
				setSharePrice={(value) => setCalculatorState((prevState) => ({ ...prevState, sharePrice: value }))}
				shareNumber={calculatorState.shareNumber}
				setShareNumber={(value) =>
					setCalculatorState((prevState) => ({
						...prevState,
						shareNumber: value,
					}))
				}
				sharesIssued={calculatorState.sharesIssued}
				submitButton={
					<LoadingButton
						variant="contained"
						type="submit"
						size={'large'}
						loading={isButtonLoading}
						endIcon={<ArrowForward />}
						loadingPosition={'end'}
						onClick={onSubmit}
						fullWidth={true}
					>
						Continue
					</LoadingButton>
				}
			/>
		</Dialog>
	);
};

export default QuickInquiryDialog;
