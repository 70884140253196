export default (
	<div>
		<h2>What is the Process for Selling Private Company Shares through ShareWell?</h2>
		<p>
			Selling private company shares through ShareWell is a straightforward process designed to connect sellers with
			potential buyers. Here's a step-by-step guide:
		</p>
		<ol>
			<li>
				<b>Create an Account on ShareWell:</b>
				<ul>
					<li>
						Start by simply registering online with ShareWell for a data account free of charge. Creating an account is
						a quick process that only takes a few minutes.
					</li>
				</ul>
			</li>
			<li>
				<b>Company and Non-Binding Inquiry:</b>
				<ul>
					<li>
						Enter the name of the private company, create a basic ‘inquiry’ for the potential quantity of shares you
						might be interested in selling, and your asking price per share.
					</li>
					<li>
						This is a <b>non-binding step</b> that launches your portfolio view, and personal dashboard which grants you
						access to our in-network bids, comprehensive pricing data, compliance onboarding, and trading.
					</li>
					<li>
						<i>
							Note: If you're unsure about the price, a ShareWell Account Manager will reach out to provide insights
							into the potential market for your shares.
						</i>
					</li>
				</ul>
			</li>
			<li>
				<b>Onboarding:</b>
				<ul>
					<li>
						This step is the preparation for conducting a sale (or purchase) that follows FINRA requirements related to
						diligence before conducting a transaction.
					</li>
					<li>
						Onboarding and compliance consist of verifying your identity and ownership status required per security
						transaction rules governing AML (Anti-Money Laundering, KYC (Know Your Customer), and OFAC (No Fly –
						Terrorist Watch) diligence.
					</li>
				</ul>
			</li>
		</ol>
		<p>
			<i>
				(Note: Each transaction is unique, and while the average is 50 days, individual circumstances may result in
				longer closing times.)
			</i>
		</p>
		<p>
			If you are considering selling your shares, ShareWell simplifies the process, providing expertise and support to
			ensure a smooth and efficient transaction.
		</p>
	</div>
);
