import React, { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
	Button,
	Card,
	CardContent,
	Link,
	Paper,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { divide, multiply } from '../../../../math.utils';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { NotificationsNone, NotificationsOff } from '@mui/icons-material';
import { useAuthContext } from '../../../../context';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { strings } from '../../../../utils/strings';
import { usePrivateAppContext } from '../../../../context/PrivateAppContext';
import { useCompanyContext } from '../../../../context/CompanyContext';
import { defaultCalculatorData } from '../../../../interfaces/IInquiryCalculator';
import mixpanel from 'mixpanel-browser';

const Calculator = ({ loading }) => {
	const { companyId } = useParams();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { data, calculatorData } = useCompanyContext();
	const { targetPrices, setTargetPrices } = useAuthContext();
	const { setQuickInquiryCalculator } = usePrivateAppContext();

	const foundInquiry = useMemo(() => {
		return targetPrices.find((inquiry) => inquiry.fields.Company[0] === companyId);
	}, [targetPrices]);
	const isMonitoring = useMemo(() => {
		return foundInquiry?.fields?.InternalStatus === 'Looker' ?? false;
	}, [foundInquiry]);

	const [calculatorState, setCalculatorState] = useState(defaultCalculatorData);

	useEffect(() => {
		if (foundInquiry) {
			setCalculatorState((prev) => ({
				...prev,
				sharePrice: foundInquiry.fields.PPS,
				shareNumber: foundInquiry.fields.Shares,
				blockSize: foundInquiry.fields['Block Size'],
			}));
		}
	}, [foundInquiry]);

	useEffect(() => {
		if (!foundInquiry && calculatorData) {
			setCalculatorState((prev) => ({
				...prev,
				...calculatorData,
			}));
		}
	}, [foundInquiry, calculatorData]);

	const sharePriceChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setCalculatorState((prevState) => ({
			...prevState,
			sharePrice: value,
			blockSize: sourceInfo.source === 'event' ? multiply(value, prevState.shareNumber) : prevState.blockSize,
		}));

		if (sourceInfo.event) {
			mixpanel.track('🧮 Use Target Price Calculator', {
				CompanyId: companyId,
				sharePrice: value,
			});
		}
	};

	const numberSharesChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setCalculatorState((prevState) => ({
			...prevState,
			shareNumber: value,
			blockSize: sourceInfo.source === 'event' ? multiply(value, prevState.sharePrice) : prevState.blockSize,
		}));

		if (sourceInfo.event) {
			mixpanel.track('🧮 Use Target Price Calculator', {
				CompanyId: companyId,
				shareNumber: value,
			});
		}
	};

	const blockSizeChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value || sourceInfo.source !== 'event') {
			return;
		}

		setCalculatorState((prevState) => ({
			...prevState,
			shareNumber: sourceInfo.source === 'event' ? divide(value, prevState.sharePrice) : prevState.shareNumber,
			blockSize: value,
		}));

		if (sourceInfo.event) {
			mixpanel.track('🧮 Use Target Price Calculator', {
				CompanyId: companyId,
				blockSize: value,
			});
		}
	};

	const onMonitoringClick = async () => {
		if (foundInquiry) {
			mixpanel.track('🧮🟥 Stop Target Price Monitoring', {
				CompanyId: companyId,
				InquiryId: foundInquiry?.id,
				Referrer: window.location.pathname,
			});

			const payload = {
				inquiryId: foundInquiry?.id,
			};

			const res = await apiClient.post(endpoints.disableTarget, payload);

			if (res.status === 200) {
				toast.success(strings.successMessages.targetPrice.stopMonitor);
				setTargetPrices(targetPrices.filter((inquiry) => inquiry.id !== foundInquiry?.id));
			}
		} else {
			mixpanel.track('🧮🟢 Start Target Price Monitoring', {
				CompanyId: companyId,
			});

			const payload = {
				companyId: companyId,
				status: 'Looker',
				blockSize: Math.round(calculatorState.blockSize * 1e2) / 1e2,
				sharePrice: Math.round(calculatorState.sharePrice * 1e2) / 1e2,
				numberOfShares: calculatorState.shareNumber,
				shadow: false,
				referrer: window.location.origin,
			};

			const res = await apiClient.post(endpoints.logInterest, payload);

			if (res.status === 200) {
				toast.success(strings.successMessages.targetPrice.startMonitor);
				setTargetPrices([res.data, ...targetPrices]);
			}
		}
	};

	const handleCreateInquiry = async () => {
		setQuickInquiryCalculator({
			companyId: companyId,
			calculator: calculatorState,
		});
	};

	if (loading) {
		return <Skeleton variant="rectangular" height={409} />;
	}

	return (
		<Card>
			<CardContent>
				<Stack spacing={isMobile ? 3 : '40px'} direction={isMobile ? 'column' : 'row'}>
					<Stack flex={1} spacing={'12px'} direction={'column'} justifyContent={isMobile ? undefined : 'space-between'}>
						<Stack spacing={isMobile ? 1 : '12px'} direction={'column'}>
							<Typography variant={'h5'}>Target Price Calculator</Typography>
							<Typography variant={'body2'} color={'text.secondary'}>
								{isMobile
									? 'Setting a target price notification helps users estimate the ongoing potential value of their assets in the secondary market by providing real-time updates. ​This feature allows users to monitor the market closely, tracking price changes and trends. By staying informed, users can better manage their portfolio strategies, make timely decisions on bids, and receive updates as markets change.'
									: 'Setting a target price notification helps users estimate the ongoing potential value of their assets in the secondary market by providing real-time updates. ​This feature allows users to monitor the market closely, tracking price changes and trends. By staying informed, users can better manage their portfolio strategies, make timely decisions on bids, and receive updates as markets change.'}
							</Typography>
						</Stack>
						<Paper elevation={5} sx={{ boxShadow: 'none', pt: 1, pb: 1, pl: '10px', pr: '10px' }}>
							<Typography variant={'body2'} color={'text.secondary'}>
								To proceed with a full inquiry and onboarding,{' '}
								<Link
									component={RouterLink}
									to={`#`}
									onClick={handleCreateInquiry}
									underline={'hover'}
									// dataCy={'calculator-inquiry'}
								>
									click here
								</Link>
								.
							</Typography>
						</Paper>
					</Stack>
					<Stack flex={1} spacing={3} direction={'column'}>
						<Stack spacing={'6px'} direction={'column'}>
							<Typography variant={'body1'} color={'text.secondary'}>
								My target price
							</Typography>
							<NumericFormat
								value={calculatorState.sharePrice}
								onValueChange={sharePriceChanged}
								thousandSeparator={true}
								decimalScale={2}
								prefix="$"
								inputMode="numeric"
								customInput={TextField}
								size={isMobile ? 'medium' : 'small'}
								data-cy={'calculator-pps'}
								helperText={`Current price: $${data?.priceData?.[data?.priceData?.length - 1]?.trading ?? '0.0'}`}
								disabled={isMonitoring}
							/>
						</Stack>
						<Stack spacing={'6px'} direction={'column'}>
							<Typography variant={'body1'} color={'text.secondary'}>
								Number of shares
							</Typography>
							<NumericFormat
								value={calculatorState.shareNumber}
								onValueChange={numberSharesChanged}
								thousandSeparator={true}
								inputMode="numeric"
								customInput={TextField}
								size={isMobile ? 'medium' : 'small'}
								data-cy={'calculator-shares'}
								disabled={isMonitoring}
							/>
						</Stack>
						<Stack spacing={'6px'} direction={'column'}>
							<Typography variant={'body1'} color={'text.secondary'}>
								Block size
							</Typography>
							<NumericFormat
								value={calculatorState.blockSize}
								onValueChange={blockSizeChanged}
								thousandSeparator={true}
								prefix="$"
								decimalScale={2}
								inputMode="numeric"
								customInput={TextField}
								size={isMobile ? 'medium' : 'small'}
								disabled={isMonitoring}
							/>
						</Stack>
						<Button
							variant={isMonitoring ? 'outlined' : 'contained'}
							size={'large'}
							color={isMonitoring ? 'error' : 'primary'}
							fullWidth
							data-cy={isMonitoring ? undefined : 'inquiry-submit'}
							startIcon={isMonitoring ? <NotificationsOff /> : <NotificationsNone />}
							onClick={onMonitoringClick}
						>
							{isMonitoring ? 'Stop monitoring' : 'Monitor price changes'}
						</Button>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default Calculator;
