import { INQUIRY_ACTION } from './IInquiry';

export interface IInquiryCalculator {
	sharePrice: number;
	shareNumber: number;
	blockSize: number;
	sharesIssued: number;
	action: INQUIRY_ACTION;
}

export const defaultCalculatorData: IInquiryCalculator = {
	sharePrice: 25,
	shareNumber: 100,
	blockSize: 150000,
	sharesIssued: 0,
	action: INQUIRY_ACTION.SELL,
};
