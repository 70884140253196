import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteJWT, getUserHomepage, setJWT } from '../utils';
import { useLoaderContext } from './LoaderContext';
import mixpanel from 'mixpanel-browser';

const AuthContext = createContext({
	user: null,
	authenticated: false,
	isDataLoading: true,
	logout: () => {},
	inquiries: [],
	setInquiries: () => {},
	reloadUserData: () => {},
	bids: [],
	updateBid: () => {},
	offers: [],
	updateOffer: () => {},
	targetPrices: [],
	setTargetPrices: () => {},
});

export const AuthProvider = ({ children }) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [authenticated, setAuthenticated] = useState(false);
	/** isDataLoading is needed to avoid router rendering before getting data */
	const [isDataLoading, setDataLoading] = useState(true);

	const [user, setUser] = useState(null);
	const [inquiries, setInquiries] = useState([]);
	const [bids, setBids] = useState([]);
	const [offers, setOffers] = useState([]);
	const [targetPrices, setTargetPrices] = useState([]);

	const { openLoader } = useLoaderContext();

	useEffect(() => {
		openLoader();
		handleInitialLoad();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getUserInfo = useCallback(async () => {
		const res = await getUserHomepage();

		if (res.data) {
			const userResponse = res.data.user;
			const instie = userResponse?.fields.UserStage !== 'Retail';

			setUser({
				...userResponse,
				isLevel2: instie && userResponse?.fields.UserStage === 'Inst2',
				isLevel3: instie && ['Inst3', 'Inst3Ask', 'Inst3Bid'].includes(userResponse?.fields.UserStage),
				instie: instie,
				isAdmin: userResponse?.fields['User Type'] === 'Admin',
				isAccredited: instie ? Boolean(userResponse?.fields.Accreditation) : true,
				shadow: Boolean(userResponse?.fields['Shadow']),
			});

			if (userResponse?.fields['Shadow']) {
				//set body background color to #6d2d83
				document.body.style.backgroundColor = '#6d2d83';
			}

			const responseInquiries = res.data.inquiries.filter(
				(inquiry) => inquiry.fields.InternalStatus !== 'Looker' && inquiry.fields.InternalStatus !== 'Ghost'
			);
			if (JSON.stringify(responseInquiries) !== JSON.stringify(inquiries)) {
				setInquiries(responseInquiries);
			}

			const responseTargetPrices = res.data.inquiries.filter(
				(inquiry) => inquiry.fields.InternalStatus === 'Looker' || inquiry.fields.InternalStatus === 'Ghost'
			);
			if (JSON.stringify(responseTargetPrices) !== JSON.stringify(targetPrices)) {
				setTargetPrices(responseTargetPrices);
			}

			if (JSON.stringify(res.data.bids) !== JSON.stringify(bids)) {
				setBids(res.data.bids ?? []);
			}

			if (JSON.stringify(res.data.offers) !== JSON.stringify(offers)) {
				setOffers(res.data.offers ?? []);
			}

			mixpanel.identify(userResponse.id);

			mixpanel.people.set({
				$name: `${userResponse.fields['First Name']} ${userResponse.fields['Last Name']}`,
				$email: userResponse.fields.Email,
				swtype:
					userResponse?.fields['User Type'] === 'Admin' || userResponse?.fields['User Type'] === 'NPC'
						? 'internal'
						: 'external',
				userStage: userResponse?.fields.UserStage,
			});

			setAuthenticated(true);
		}
	}, [inquiries, bids, offers, targetPrices]);

	const handleInitialLoad = async () => {
		try {
			const token = searchParams.get('token');

			if (token) {
				setJWT(token).catch((e) => e);
			}

			await getUserInfo();
		} catch (err) {
			setAuthenticated(false);
			deleteJWT().catch((e) => e);
		} finally {
			setDataLoading(false);
		}
	};

	const logout = () => {
		setAuthenticated(false);
		localStorage.removeItem('JWT');
		navigate('/');
	};

	const reloadUserData = async () => {
		await getUserInfo();
	};

	const updateBid = (bid) => {
		setBids((prevState) =>
			prevState.map((prevBid) => {
				if (prevBid.id === bid.id) {
					return bid;
				}

				return prevBid;
			})
		);
	};

	const updateOffer = (offer) => {
		setOffers((prevState) =>
			prevState.map((prevOffer) => {
				if (prevOffer.id === offer.id) {
					return offer;
				}

				return prevOffer;
			})
		);
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				authenticated,
				logout,
				inquiries,
				setInquiries,
				reloadUserData,
				isDataLoading,
				bids,
				updateBid,
				updateOffer,
				targetPrices,
				setTargetPrices,
				offers,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);
