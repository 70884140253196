import { LabelTypography } from './common.styles';
import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid2';

export const labelColumns = 4;
export const valueColumns = 12 - labelColumns;

export const Label: React.FC<PropsWithChildren & { required?: boolean }> = ({ children, required }) => (
	<Grid size={labelColumns}>
		<LabelTypography $required={required}>{children}</LabelTypography>
	</Grid>
);
