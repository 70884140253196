import * as Yup from 'yup';
import { defaultCalculatorData } from '../../interfaces/IInquiryCalculator';

export const companyInsiderYes = 'Yes, I am a company insider';
export const companyAffiliateYes = 'Yes, I am affiliate';

export interface OnboardingForm {
	//calculator
	calculator: {
		Action: string;
		'Block Size': number;
		PPS: number;
		Shares: number;
		sharesIssued: number;
	};

	// onboarding
	stillInCompany: string;
	position: string;
	whenDidYouLeave: string;
	Equity: Array<any>;
	Share: Array<any>;
	Grant: Array<any>;
	aonOrMin: string;
	minQty: string;
	gtcOrExpirationDate: string;
	expirationDate: string;
	companyInsider: string;
	companyInsiderEmployed: string;
	companyInsiderEmployedExplanation: string;
	companyInsiderControl: string;
	companyInsiderControlExplanation: string;
	companyInsiderPolitical: string;
	companyInsiderPoliticalExplanation: string;
	companyAffiliate: string;
	companyAffiliateEmployed: string;
	companyAffiliateEmployedExplanation: string;
	companyAffiliateControl: string;
	companyAffiliateControlExplanation: string;
	companyAffiliatePolitical: string;
	companyAffiliatePoliticalExplanation: string;

	// shareholder profile
	middleNames: string;
	hasMiddleNames: boolean;
	phoneNumber: string;
	ssnTaxId: string;
	currentMailingAddress: string;
	residingTime: string;
	formerAddress: string;
	usCitizen: string;
	citizenship: string;
	birthDate: string;
	brokerDealerAssociated: string;
	brokerDealerAssociatedEmployed: string;
	brokerDealerAssociatedEmployedExplanation: string;
	brokerDealerAssociatedControl: string;
	brokerDealerAssociatedControlExplanation: string;
	brokerDealerAssociatedPolitical: string;
	brokerDealerAssociatedPoliticalExplanation: string;
	rule506: string;
	rule506Explanation: string;
	criminalOffense: string;
	criminalOffenseExplanation: string;
	pendingLegalLitigation: string;
	pendingLegalLitigationExplanation: string;
	judgments: string;
	judgmentsExplanation: string;
	foreclosure: string;
	foreclosureExplanation: string;
	proceedings: string;
	proceedingsExplanation: string;
	idPassport: string;
	Identity: Array<any>;
}

export const initialValues: OnboardingForm = {
	//calculator
	calculator: {
		Action: defaultCalculatorData.action,
		'Block Size': defaultCalculatorData.blockSize,
		PPS: defaultCalculatorData.sharePrice,
		Shares: defaultCalculatorData.shareNumber,
		sharesIssued: defaultCalculatorData.sharesIssued,
	},

	// onboarding
	stillInCompany: '',
	position: '',
	whenDidYouLeave: '',
	Equity: [],
	Share: [],
	Grant: [],
	aonOrMin: '',
	minQty: '',
	gtcOrExpirationDate: '',
	expirationDate: '',
	companyInsider: '',
	companyInsiderEmployed: '',
	companyInsiderEmployedExplanation: '',
	companyInsiderControl: '',
	companyInsiderControlExplanation: '',
	companyInsiderPolitical: '',
	companyInsiderPoliticalExplanation: '',
	companyAffiliate: '',
	companyAffiliateEmployed: '',
	companyAffiliateEmployedExplanation: '',
	companyAffiliateControl: '',
	companyAffiliateControlExplanation: '',
	companyAffiliatePolitical: '',
	companyAffiliatePoliticalExplanation: '',

	// shareholder profile
	middleNames: '',
	hasMiddleNames: true,
	phoneNumber: '',
	ssnTaxId: '',
	currentMailingAddress: '',
	residingTime: '',
	formerAddress: '',
	usCitizen: '',
	citizenship: '',
	birthDate: '',
	brokerDealerAssociated: '',
	brokerDealerAssociatedEmployed: '',
	brokerDealerAssociatedEmployedExplanation: '',
	brokerDealerAssociatedControl: '',
	brokerDealerAssociatedControlExplanation: '',
	brokerDealerAssociatedPolitical: '',
	brokerDealerAssociatedPoliticalExplanation: '',
	rule506: '',
	rule506Explanation: '',
	criminalOffense: '',
	criminalOffenseExplanation: '',
	pendingLegalLitigation: '',
	pendingLegalLitigationExplanation: '',
	judgments: '',
	judgmentsExplanation: '',
	foreclosure: '',
	foreclosureExplanation: '',
	proceedings: '',
	proceedingsExplanation: '',
	idPassport: '',
	Identity: [],
};

export const schema = Yup.object({
	// onboarding
	stillInCompany: Yup.string().required('Required'),
	position: Yup.string().when('stillInCompany', {
		is: (stillInCompany) => stillInCompany === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	whenDidYouLeave: Yup.string().when('stillInCompany', {
		is: (stillInCompany) => stillInCompany === 'No',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	Equity: Yup.array().min(1, 'Required'),
	Share: Yup.array().min(1, 'Required'),
	Grant: Yup.array().min(1, 'Required'),
	aonOrMin: Yup.string().required('Required'),
	minQty: Yup.string().when('aonOrMin', {
		is: (aonOrMin) => aonOrMin === 'Min',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	gtcOrExpirationDate: Yup.string().required('Required'),
	expirationDate: Yup.string().when('gtcOrExpirationDate', {
		is: (gtcOrExpirationDate) => gtcOrExpirationDate === 'Expiration Date',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	// companyInsider
	companyInsider: Yup.string().required('Required'),
	companyInsiderEmployed: Yup.string().when('companyInsider', {
		is: (companyInsider) => companyInsider === companyInsiderYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderEmployedExplanation: Yup.string().when('companyInsiderEmployed', {
		is: (companyInsiderEmployed) => companyInsiderEmployed === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderControl: Yup.string().when('companyInsider', {
		is: (companyInsider) => companyInsider === companyInsiderYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderControlExplanation: Yup.string().when('companyInsiderControl', {
		is: (companyInsiderControl) => companyInsiderControl === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderPolitical: Yup.string().when('companyInsider', {
		is: (companyInsider) => companyInsider === companyInsiderYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderPoliticalExplanation: Yup.string().when('companyInsiderPolitical', {
		is: (companyInsiderPolitical) => companyInsiderPolitical === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	// companyAffiliate
	companyAffiliate: Yup.string().required('Required'),
	companyAffiliateEmployed: Yup.string().when('companyAffiliate', {
		is: (companyAffiliate) => companyAffiliate === companyAffiliateYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliateEmployedExplanation: Yup.string().when('companyAffiliateEmployed', {
		is: (companyAffiliateEmployed) => companyAffiliateEmployed === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliateControl: Yup.string().when('companyAffiliate', {
		is: (companyAffiliate) => companyAffiliate === companyAffiliateYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliateControlExplanation: Yup.string().when('companyAffiliateControl', {
		is: (companyAffiliateControl) => companyAffiliateControl === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliatePolitical: Yup.string().when('companyAffiliate', {
		is: (companyAffiliate) => companyAffiliate === companyAffiliateYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliatePoliticalExplanation: Yup.string().when('companyAffiliatePolitical', {
		is: (companyAffiliatePolitical) => companyAffiliatePolitical === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	// shareholder profile
	middleNames: Yup.string().when('hasMiddleNames', {
		is: (hasMiddleNames) => hasMiddleNames,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	hasMiddleNames: Yup.boolean().notRequired(),
	phoneNumber: Yup.string().required('Required'),
	ssnTaxId: Yup.string().required('Required'),
	currentMailingAddress: Yup.string().required('Required'),
	residingTime: Yup.string().required('Required'),
	formerAddress: Yup.string().when('residingTime', {
		is: (residingTime) => residingTime === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	usCitizen: Yup.string().required('Required'),
	citizenship: Yup.string().when('usCitizen', {
		is: (usCitizen) => usCitizen === 'No',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	birthDate: Yup.string().required('Required'),

	// brokerDealerAssociated
	brokerDealerAssociated: Yup.string().required('Required'),
	brokerDealerAssociatedEmployed: Yup.string().when('brokerDealerAssociated', {
		is: (brokerDealerAssociated) => brokerDealerAssociated === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedEmployedExplanation: Yup.string().when('brokerDealerAssociatedEmployed', {
		is: (brokerDealerAssociatedEmployed) => brokerDealerAssociatedEmployed === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedControl: Yup.string().when('brokerDealerAssociated', {
		is: (brokerDealerAssociated) => brokerDealerAssociated === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedControlExplanation: Yup.string().when('brokerDealerAssociatedControl', {
		is: (brokerDealerAssociatedControl) => brokerDealerAssociatedControl === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedPolitical: Yup.string().when('brokerDealerAssociated', {
		is: (brokerDealerAssociated) => brokerDealerAssociated === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedPoliticalExplanation: Yup.string().when('brokerDealerAssociatedPolitical', {
		is: (brokerDealerAssociatedPolitical) => brokerDealerAssociatedPolitical === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	/* Attestations */
	rule506: Yup.string().required('Required'),
	rule506Explanation: Yup.string().when('rule506', {
		is: (rule506) => rule506 === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	criminalOffense: Yup.string().required('Required'),
	criminalOffenseExplanation: Yup.string().when('criminalOffense', {
		is: (criminalOffense) => criminalOffense === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	pendingLegalLitigation: Yup.string().required('Required'),
	pendingLegalLitigationExplanation: Yup.string().when('pendingLegalLitigation', {
		is: (pendingLegalLitigation) => pendingLegalLitigation === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	judgments: Yup.string().required('Required'),
	judgmentsExplanation: Yup.string().when('judgments', {
		is: (judgments) => judgments === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	foreclosure: Yup.string().required('Required'),
	foreclosureExplanation: Yup.string().when('foreclosure', {
		is: (foreclosure) => foreclosure === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	proceedings: Yup.string().required('Required'),
	proceedingsExplanation: Yup.string().when('proceedings', {
		is: (proceedings) => proceedings === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	idPassport: Yup.string().required('Required'),
	Identity: Yup.array().min(1, 'Required'),
});
