import { useEffect, useState } from 'react';
import { Fade, Stack, Typography } from '@mui/material';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import { useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

const AutoSave = ({ handleSubmit, savedTime, enabled = true }) => {
	const { values } = useFormikContext();
	const [isVisible, setIsVisible] = useState(false);
	const [isFirstRender, setFirstRender] = useState(true);

	useEffect(() => {
		if (savedTime) {
			setIsVisible(true);

			const timer = setTimeout(() => {
				setIsVisible(false);
			}, 5000); // Hide the notification after 5 seconds

			return () => clearTimeout(timer); // Cleanup on component unmount or value changes
		}
	}, [savedTime]);

	const saveDraft = useDebouncedCallback((values) => {
		handleSubmit(values);
	}, 2000); // 2-second delay

	useEffect(() => {
		if (isFirstRender) {
			setFirstRender(false);
		} else {
			enabled && saveDraft(values);
		}
	}, [values]);

	return (
		<Fade in={Boolean(isVisible && savedTime)}>
			<Stack direction={'row'} alignItems={'center'} spacing={'6px'} justifyContent={'center'}>
				<CheckCircleOutlined color={'success'} sx={{ fontSize: 16 }} />
				<Typography variant={'body2'}>
					Changes saved to draft{' '}
					<Typography variant={'body2'} color={'text.secondary'} component={'span'}>
						({savedTime})
					</Typography>
				</Typography>
			</Stack>
		</Fade>
	);
};

export default AutoSave;
