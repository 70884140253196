import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { alpha, Box, Card, CardContent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import PageTitle from '../../components/PageTitle';
import { DoneAll, Save } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { apiClient, endpoints, strings, timeWithSecondsFormatter } from '../../utils';
import Grid from '@mui/material/Grid2';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { initialValues, OnboardingForm, schema } from './formConstants';
import { constants } from '../../utils/constants';
import mixpanel from 'mixpanel-browser';

import InquiryPreview from '../../components/InquiryPreview';
import ThankYouBlock from '../../components/ThankYouBlock';
import ShareholderProfileBlock from './components/ShareholderProfileBlock';
import AutoSave from './components/AutoSave';
import FormikErrorFocus from 'formik-error-focus';
import ConfirmDialog from './components/ConfirmDialog';
import OnboardingSkeleton from '../../components/skeletons/OnboardingSkeleton';
import OnboardingBlock from './components/OnboardingBlock';
import InquiryCalculator from '../../components/InquiryCalculator';
import { useDebouncedCallback } from 'use-debounce';

const OnboardingView = () => {
	const { inquiryId, activityLogId } = useParams();
	const { inquiries, user } = useAuthContext();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isFormLoaded, setFormLoaded] = useState<boolean>(false);
	const [locked, setLocked] = useState<boolean>(false);
	const [inquiry, setInquiry] = useState<any>(null);
	const [formData, setFormData] = useState<OnboardingForm>(null);
	const [isDraftButtonLoading, setDraftButtonLoading] = useState<boolean>(false);
	const [isCompleteButtonLoading, setCompleteButtonLoading] = useState<boolean>(false);
	const [isSuccess, setSuccess] = useState<boolean>(false);
	const [savedTime, setSavedTime] = useState<string>(null);
	const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);

	useEffect(() => {
		if (window.location.pathname.includes('success') && !isSuccess) {
			navigate(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
		}
	}, []);

	useEffect(() => {
		if (activityLogId) {
			const inquiry = inquiries.find((inq: any) => inq.id === inquiryId);
			const activityLog = inquiry?.activityLogs?.find(
				(activityLog: any) =>
					activityLog.fields['Type'] === constants.ACTIVITY_LOG.TYPE.JointOnboarding && activityLog.id === activityLogId
			);

			if (activityLog) {
				setInquiry(inquiry);

				if (activityLog.fields['Questionnaire']) {
					if (activityLog.fields['Locked']) {
						setLocked(true);
					}
					const formData = JSON.parse(activityLog.fields['Questionnaire']);

					if (formData) {
						setFormData({
							...formData,
							Equity: activityLog.fields.Equity ?? [],
							Share: activityLog.fields.Share ?? [],
							Grant: activityLog.fields.Grant ?? [],
							Identity: activityLog.fields.Identity ?? [],
							birthDate: formData.birthDate ? new Date(formData.birthDate) : '',
							calculator: {
								Action: inquiry.fields.Action,
								'Block Size': inquiry.fields['Block Size'],
								PPS: inquiry.fields.PPS,
								Shares: inquiry.fields.Shares,
								sharesIssued: inquiry.fields.sharesIssued,
							},
						});
					}
				} else {
					setFormData({
						calculator: {
							Action: inquiry.fields.Action,
							'Block Size': inquiry.fields['Block Size'],
							PPS: inquiry.fields.PPS,
							Shares: inquiry.fields.Shares,
							sharesIssued: inquiry.fields.sharesIssued,
						},
					} as OnboardingForm);
				}

				setLoading(false);
				mixpanel.track('📄️ Open onboarding', {
					InquiryId: inquiryId,
				});
				setTimeout(() => {
					setFormLoaded(true);
				}, 800);
			} else {
				navigate('/home');
				toast.error(strings.errorMessages.inquiry.load);
			}
		}
	}, [activityLogId, inquiries]);

	const onSaveDraft = (values: any) => {
		mixpanel.track('📄💾 Save onboarding draft', {
			InquiryId: inquiryId,
		});
		setDraftButtonLoading(true);
		handleSubmit(values, false)
			.then((response) => {
				if (response?.status === 200) {
					setSavedTime(timeWithSecondsFormatter(new Date()));
				}
			})
			.finally(() => setDraftButtonLoading(false));
	};

	const onComplete = (values: any) => {
		mixpanel.track('📄🚀 Complete onboarding', {
			InquiryId: inquiryId,
		});
		setCompleteButtonLoading(true);
		handleSubmit(values, true)
			.then((response) => {
				if (response?.status === 200) {
					setSuccess(true);
					setLocked(true);

					if (!window.location.pathname.includes('success')) {
						navigate(window.location.pathname + '/success');
					}
				}
			})
			.finally(() => {
				setCompleteButtonLoading(false);
				setConfirmOpen(false);
			});
	};

	const cleanupFiles = async (documents: any[]) => {
		const newFiles = [...documents].filter((file) => file.name && !file.id);
		const uploadedFiles = [...documents].filter((file) => file.id);

		for (const uploadedFile of uploadedFiles) {
			const blob = await fetch(uploadedFile.url).then((r) => r.blob());
			const parts = [blob];
			const file = new File(parts, uploadedFile.filename, { ...uploadedFile });
			newFiles.push(file);
		}
		return newFiles;
	};

	const handleSubmit = async (values: OnboardingForm, locked: boolean) => {
		try {
			//firstly save documents
			const formData = new FormData();
			let hasData = false;

			if (values.Equity && values.Equity.length > 0) {
				(await cleanupFiles(values.Equity)).forEach((file) => {
					formData.append('equityFiles', file);
				});
				hasData = true;
			}

			if (values.Share && values.Share.length > 0) {
				(await cleanupFiles(values.Share)).forEach((file) => {
					formData.append('shareFiles', file);
				});
				hasData = true;
			}

			if (values.Grant && values.Grant.length > 0) {
				(await cleanupFiles(values.Grant)).forEach((file) => {
					formData.append('grantFiles', file);
				});
				hasData = true;
			}

			if (values.Identity && values.Identity.length > 0) {
				(await cleanupFiles(values.Identity)).forEach((file) => {
					formData.append('identityFiles', file);
				});
				hasData = true;
			}

			if (hasData) {
				await apiClient.post(endpoints.uploadTransactionFiles.replace('{recordId}', activityLogId), formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
			}

			//secondly update inquiry
			const inquiryPayload = {
				action: values.calculator.Action,
				blockSize: Math.round(values.calculator['Block Size'] * 1e2) / 1e2,
				companyName: inquiry.fields['Company Name'][0],
				companyId: inquiry.fields['Company'][0],
				sharePrice: Math.round(values.calculator.PPS * 1e2) / 1e2,
				numberOfShares: values.calculator.Shares,
				sharesIssued: values.calculator.sharesIssued,

				referrer: window.location.origin,
				inquiryId: inquiryId,
				shadow: !!user.shadow,
			};
			await apiClient.post(endpoints.updateInquiry, inquiryPayload);

			//thirdly save questionnaire
			const payload = JSON.stringify({
				id: activityLogId,
				questionnaire: JSON.stringify({ ...values, calculator: undefined }),
				locked: locked,
			});

			return await apiClient.post(endpoints.submitQuestionnaireForm, payload, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (err) {
			toast.error(strings.errorMessages.onboarding.save);
		}
	};

	const trackFormEditing = useDebouncedCallback(() => {
		mixpanel.track('📄✏️ Edit onboarding', {
			InquiryId: inquiryId,
		});
	}, 1800000);

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{ ...initialValues, ...formData }}
			onSubmit={onComplete}
			validationSchema={schema}
			validateOnBlur={false}
			validateOnChange={false}
		>
			{(formikProps) => {
				const { submitForm, values, validateForm, setTouched, setFieldValue } = formikProps;

				const renderRightPanel = () => {
					if (!isLoading) {
						return (
							<Stack
								direction={isMobile ? 'column' : 'row'}
								alignItems={isMobile ? 'stretch' : 'center'}
								spacing={isMobile ? '12px' : 2}
							>
								{!locked && isFormLoaded && (
									<AutoSave savedTime={savedTime} handleSubmit={onSaveDraft} enabled={false} />
								)}
								<Stack direction={isMobile ? 'column' : 'row'} alignItems={'center'} spacing={1}>
									{!locked && (
										<LoadingButton
											variant="outlined"
											loading={isDraftButtonLoading}
											startIcon={<Save />}
											loadingPosition={'start'}
											onClick={() => onSaveDraft(values)}
											size={isMobile ? 'large' : 'medium'}
											fullWidth={isMobile}
										>
											save draft
										</LoadingButton>
									)}
									{!isSuccess && (
										<LoadingButton
											variant="contained"
											type="submit"
											loading={isCompleteButtonLoading}
											startIcon={locked ? undefined : <DoneAll />}
											loadingPosition={'start'}
											disabled={locked}
											onClick={() => {
												validateForm().then((errors) => {
													if (Object.keys(errors).length === 0) {
														setConfirmOpen(true);
													} else {
														setTouched(Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
													}
												});
											}}
											size={isMobile ? 'large' : 'medium'}
											fullWidth={isMobile}
										>
											{locked ? 'Completed' : 'Complete Submission'}
										</LoadingButton>
									)}
								</Stack>
							</Stack>
						);
					}
				};

				return (
					<Form autoComplete="off" onChange={trackFormEditing}>
						<FormikErrorFocus offset={0} align={'top'} focusDelay={0} formik={formikProps} />
						<Stack
							id={'onboarding-header'}
							sx={
								!isMobile && {
									position: 'sticky',
									top: 0, // Stick to the top of the viewport
									zIndex: 2, // Ensure it stays above other elements
									backgroundColor: 'background.paper', // Background to prevent transparency
								}
							}
						>
							<PageTitle
								isLoading={isLoading}
								breadcrumbs={[
									{
										title: 'Home',
										link: '/home',
									},
									{
										title: 'Onboarding',
									},
								]}
								title={'Onboarding'}
								rightPanel={!isMobile && renderRightPanel()}
							/>
						</Stack>
						{isMobile && (
							<Box
								sx={{
									position: 'fixed',
									width: '100dvw',
									left: 0,
									bottom: 0,
									pb: 3,
									pl: 3,
									pr: 3,
									zIndex: 1051,
									background:
										'linear-gradient(0deg, #121212 47.32%, rgba(18, 18, 18, 0.70) 86%, rgba(18, 18, 18, 0.00) 100%)',
								}}
							>
								{renderRightPanel()}
							</Box>
						)}
						<Box mb={isMobile ? '150px' : 0}>
							{isLoading ? (
								<OnboardingSkeleton />
							) : (
								<Grid container spacing={3}>
									<Grid
										size={isMobile ? 12 : 4}
										id={'onboarding-preview'}
										sx={
											isMobile
												? undefined
												: {
														position: 'sticky',
														top: 128,
														alignSelf: 'flex-start',
														zIndex: 1,
													}
										}
									>
										{locked ? (
											<InquiryPreview
												companyName={inquiry.fields['Company Name'][0]}
												actionValue={inquiry.fields.Action}
												shareNum={inquiry.fields.Shares}
												pps={inquiry.fields.PPS}
											/>
										) : (
											<InquiryCalculator
												title={inquiry.fields['Company Name'][0]}
												action={values.calculator.Action}
												setAction={(value) => setFieldValue('calculator.Action', value)}
												blockSize={values.calculator['Block Size']}
												setBlockSize={(value) => setFieldValue('calculator.Block Size', value)}
												sharePrice={values.calculator.PPS}
												setSharePrice={(value) => setFieldValue('calculator.PPS', value)}
												shareNumber={values.calculator.Shares}
												setShareNumber={(value) => setFieldValue('calculator.Shares', value)}
												sharesIssued={values.calculator.sharesIssued}
											/>
										)}
									</Grid>
									{isSuccess ? (
										<Grid size={isMobile ? 12 : 8} marginTop={'60px'} marginBottom={'60px'}>
											<ThankYouBlock
												title={'Thank you for submitting your responses!'}
												description={'Our team will review them and reach out to you soon with next steps.'}
												buttonText={'go home'}
												buttonLink={'/'}
											/>
										</Grid>
									) : (
										<Grid size={isMobile ? 12 : 8}>
											<Stack spacing={2}>
												{!locked && (
													<Card sx={{ p: 0, background: alpha(theme.palette.primary.main, 0.16) }}>
														<CardContent sx={{ p: '12px 14px !important' }}>
															<Typography variant="body2">
																Please do your best to complete the the following fields accurately; which allows us to
																better understand your potential transactional preferences. You will have a chance to
																connect with your Relationship Manager to discuss pricing, process and market conditions
																once this information (and materials) have been collected.
															</Typography>
														</CardContent>
													</Card>
												)}
												<OnboardingBlock locked={locked} />
												<ShareholderProfileBlock locked={locked} />
											</Stack>
										</Grid>
									)}
								</Grid>
							)}
						</Box>
						<ConfirmDialog isOpen={isConfirmOpen} handleClose={() => setConfirmOpen(false)} submitForm={submitForm} />
					</Form>
				);
			}}
		</Formik>
	);
};
export default OnboardingView;
